<i18n lang="json" locale="fr-FR">
{
  "logo": {
    "title": "@:{'global.action.goToHomepage'}"
  }
}
</i18n>

<script lang="ts" setup>
import OnlizLogo from '@onliz/design-system-assets/logo/logo-onliz.svg?component';
import { OdsClickableElement } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <LayoutStickyBar
    tag="header"
    class="layout-landing-header"
    :class="{ 'layout-landing-header--with-cta': !!$slots.default }"
  >
    <LayoutContainer class="layout-landing-header__container">
      <div class="layout-landing-header__wrapper">
        <OdsClickableElement class="layout-landing-header__logo" to="/" :title="t('logo.title')">
          <OnlizLogo />
        </OdsClickableElement>
      </div>

      <div class="layout-landing-header__contact">
        <SavPhoneNumber show-info />
      </div>

      <div v-if="$slots.default" class="layout-landing-header__cta">
        <slot></slot>
      </div>
    </LayoutContainer>
  </LayoutStickyBar>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;
@use '@onliz/design-system-scss/themes/onliz.scss' as theme;
@use 'sass:map';

.layout-landing-header {
  $block-selector: &;
  $layout-landing-header-border: 1px solid var(--scheme--border);
  $layout-landing-header-gap: var(--spacing--m);

  &__container {
    background-color: var(--scheme--background);
    display: grid;
    border-bottom: $layout-landing-header-border;
    grid-template: 'wrapper contact' auto / 1fr auto;
    gap: var(--spacing--xs);
    padding-top: var(--spacing--s);
    padding-bottom: var(--spacing--s);

    @media screen and (min-width: ods.$breakpoint-xs) {
      gap: var(--spacing--s);
    }

    @media screen and (min-width: ods.$breakpoint-m) {
      gap: var(--spacing--m);
    }

    #{$block-selector}--with-cta & {
      grid-template:
        'wrapper cta cta' 1fr
        'contact contact contact' auto / 1fr;

      @media screen and (min-width: ods.$breakpoint-xs) {
        grid-template: 'wrapper contact cta' 1fr / 1fr auto auto;
      }
    }

    [data-sticky='true'] & {
      border-bottom-color: transparent;
    }
  }

  &__wrapper {
    grid-area: wrapper;
    display: flex;
    align-items: center;
  }

  &__contact {
    grid-area: contact;
    border-left: $layout-landing-header-border;
    padding-left: $layout-landing-header-gap;

    #{$block-selector}--with-cta & {
      @media screen and (max-width: ods.$breakpoint-xs) {
        border-left: none;
        padding-left: 0;
      }
    }
  }

  &__cta {
    grid-area: cta;
  }

  &__logo {
    width: 100%;
    max-width: 115px;
    display: block;
  }
}
</style>
