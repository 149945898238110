<script setup lang="ts">
useUseOrganizationJsonLd();
useRelCanonical();
</script>

<template>
  <div class="layout-landing">
    <LayoutLandingHeader class="layout-landing__header">
      <template v-if="$slots.cta" #default>
        <slot name="cta"></slot>
      </template>
    </LayoutLandingHeader>

    <div class="layout-landing__main">
      <slot></slot>
    </div>

    <LayoutLandingFooter />
  </div>
</template>

<style lang="scss">
@use '~/assets/scss/mixins-utils' as mixins;

.layout-landing {
  @include mixins.full-height-container;

  &__main {
    flex: 1;
  }
}
</style>
