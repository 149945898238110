<script setup lang="ts">
import { assembleCacheKey, CACHE_TTL_S_12_HOURS, CACHE_TTL_S_15_MIN, NAVIGATION_TAG } from '~/lib/cache-utils';
import GetNavigationGQL from '~/queries/navigation.gql';
import type { GetNavigationQuery, NavigationItem } from '~/types/graphql';

const { data: footerNavigationLegals } = await useCachedAsyncQuery<GetNavigationQuery>(
  assembleCacheKey('nav', CmsFooterNavigationSlug.legals),
  GetNavigationGQL,
  { slug: CmsFooterNavigationSlug.legals },
  {
    deep: false,
    serverMaxAge: CACHE_TTL_S_12_HOURS,
    clientMaxAge: CACHE_TTL_S_15_MIN,
    serverCacheTags: [NAVIGATION_TAG],
  },
);

const items = computed<NavigationItem[]>(() => {
  return (footerNavigationLegals.value?.renderNavigation as NavigationItem[]) ?? [];
});
</script>

<template>
  <LayoutSection scheme="primary" class="layout-landing-footer">
    <div class="layout-landing-footer__wrapper">
      <LayoutFooterLegals class="layout-landing-footer__nav" :items />

      <LayoutFooterSocial class="layout-landing-footer__social" show-title />
    </div>
  </LayoutSection>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-landing-footer {
  padding-top: 0;

  &__wrapper {
    padding: var(--spacing--m) 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing--s);
    align-items: center;

    @media screen and (min-width: ods.$breakpoint-l) {
      flex-direction: row;
      gap: var(--spacing--s);
      justify-content: space-between;
    }
  }
}
</style>
